export const regionCascadMap = {
  北京: [
    '东城区',
    '西城区',
    '朝阳区',
    '丰台区',
    '石景山区',
    '海淀区',
    '门头沟区',
    '房山区',
    '通州区',
    '顺义区',
    '昌平区',
    '大兴区',
    '怀柔区',
    '平谷区',
    '密云区',
    '延庆区',
  ],
  天津: [
    '和平区',
    '河东区',
    '河西区',
    '南开区',
    '河北区',
    '红桥区',
    '东丽区',
    '西青区',
    '津南区',
    '北辰区',
    '武清区',
    '宝坻区',
    '滨海新区',
    '宁河区',
    '静海区',
    '蓟州区',
  ],
  河北: ['石家庄', '唐山', '秦皇岛', '邯郸', '邢台', '保定', '张家口', '承德', '沧州', '廊坊', '衡水'],
  山西: ['太原', '大同', '阳泉', '长治', '晋城', '朔州', '晋中', '运城', '忻州', '临汾', '吕梁'],
  内蒙古自治区: [
    '呼和浩特',
    '包头',
    '乌海',
    '赤峰',
    '通辽',
    '鄂尔多斯',
    '呼伦贝尔',
    '巴彦淖尔',
    '乌兰察布',
    '兴安盟',
    '锡林郭勒盟',
    '阿拉善盟',
  ],
  辽宁: [
    '沈阳',
    '大连',
    '鞍山',
    '抚顺',
    '本溪',
    '丹东',
    '锦州',
    '营口',
    '阜新',
    '辽阳',
    '盘锦',
    '铁岭',
    '朝阳',
    '葫芦岛',
  ],
  吉林: ['长春', '吉林', '四平', '辽源', '通化', '白山', '松原', '白城', '延边朝鲜族自治州'],
  黑龙江: [
    '哈尔滨',
    '齐齐哈尔',
    '鸡西',
    '鹤岗',
    '双鸭山',
    '大庆',
    '伊春',
    '佳木斯',
    '七台河',
    '牡丹江',
    '黑河',
    '绥化',
    '大兴安岭地区',
  ],
  上海: [
    '黄浦区',
    '徐汇区',
    '长宁区',
    '静安区',
    '普陀区',
    '虹口区',
    '杨浦区',
    '闵行区',
    '宝山区',
    '嘉定区',
    '浦东新区',
    '金山区',
    '松江区',
    '青浦区',
    '奉贤区',
    '崇明区',
  ],
  江苏: ['南京', '无锡', '徐州', '常州', '苏州', '南通', '连云港', '淮安', '盐城', '扬州', '镇江', '泰州', '宿迁'],
  浙江: ['杭州', '宁波', '温州', '嘉兴', '湖州', '绍兴', '金华', '衢州', '舟山', '台州', '丽水'],
  安徽: [
    '合肥',
    '芜湖',
    '蚌埠',
    '淮南',
    '马鞍山',
    '淮北',
    '铜陵',
    '安庆',
    '黄山',
    '滁州',
    '阜阳',
    '宿州',
    '六安',
    '亳州',
    '池州',
    '宣城',
  ],
  福建: ['福州', '厦门', '莆田', '三明', '泉州', '漳州', '南平', '龙岩', '宁德'],
  江西: ['南昌', '景德镇', '萍乡', '九江', '新余', '鹰潭', '赣州', '吉安', '宜春', '抚州', '上饶'],
  山东: [
    '济南',
    '青岛',
    '淄博',
    '枣庄',
    '东营',
    '烟台',
    '潍坊',
    '济宁',
    '泰安',
    '威海',
    '日照',
    '临沂',
    '德州',
    '聊城',
    '滨州',
    '菏泽',
  ],
  河南: [
    '郑州',
    '开封',
    '洛阳',
    '平顶山',
    '安阳',
    '鹤壁',
    '新乡',
    '焦作',
    '濮阳',
    '许昌',
    '漯河',
    '三门峡',
    '南阳',
    '商丘',
    '信阳',
    '周口',
    '驻马店',
    '济源',
  ],
  湖北: [
    '武汉',
    '黄石',
    '十堰',
    '宜昌',
    '襄阳',
    '鄂州',
    '荆门',
    '孝感',
    '荆州',
    '黄冈',
    '咸宁',
    '随州',
    '恩施土家族苗族自治州',
    '仙桃',
    '潜江',
    '天门',
    '神农架林区',
  ],
  湖南: [
    '长沙',
    '株洲',
    '湘潭',
    '衡阳',
    '邵阳',
    '岳阳',
    '常德',
    '张家界',
    '益阳',
    '郴州',
    '永州',
    '怀化',
    '娄底',
    '湘西土家族苗族自治州',
  ],
  广东: [
    '广州',
    '韶关',
    '深圳',
    '珠海',
    '汕头',
    '佛山',
    '江门',
    '湛江',
    '茂名',
    '肇庆',
    '惠州',
    '梅州',
    '汕尾',
    '河源',
    '阳江',
    '清远',
    '东莞',
    '中山',
    '潮州',
    '揭阳',
    '云浮',
  ],
  广西壮族自治区: [
    '南宁',
    '柳州',
    '桂林',
    '梧州',
    '北海',
    '防城港',
    '钦州',
    '贵港',
    '玉林',
    '百色',
    '贺州',
    '河池',
    '来宾',
    '崇左',
  ],
  海南: [
    '海口',
    '三亚',
    '三沙',
    '儋州',
    '五指山',
    '琼海',
    '文昌',
    '万宁',
    '东方',
    '定安县',
    '屯昌县',
    '澄迈县',
    '临高县',
    '白沙黎族自治县',
    '昌江黎族自治县',
    '乐东黎族自治县',
    '陵水黎族自治县',
    '保亭黎族苗族自治县',
    '琼中黎族苗族自治县',
  ],
  重庆: [
    '万州区',
    '涪陵区',
    '渝中区',
    '大渡口区',
    '江北区',
    '沙坪坝区',
    '九龙坡区',
    '南岸区',
    '北碚区',
    '綦江区',
    '大足区',
    '渝北区',
    '巴南区',
    '黔江区',
    '长寿区',
    '江津区',
    '合川区',
    '永川区',
    '南川区',
    '璧山区',
    '铜梁区',
    '潼南区',
    '荣昌区',
    '开州区',
    '梁平区',
    '武隆区',
    '城口县',
    '丰都县',
    '垫江县',
    '忠县',
    '云阳县',
    '奉节县',
    '巫山县',
    '巫溪县',
    '石柱土家族自治县',
    '秀山土家族苗族自治县',
    '酉阳土家族苗族自治县',
    '彭水苗族土家族自治县',
  ],
  四川: [
    '成都',
    '自贡',
    '攀枝花',
    '泸州',
    '德阳',
    '绵阳',
    '广元',
    '遂宁',
    '内江',
    '乐山',
    '南充',
    '眉山',
    '宜宾',
    '广安',
    '达州',
    '雅安',
    '巴中',
    '资阳',
    '阿坝藏族羌族自治州',
    '甘孜藏族自治州',
    '凉山彝族自治州',
  ],
  贵州: [
    '贵阳',
    '六盘水',
    '遵义',
    '安顺',
    '毕节',
    '铜仁',
    '黔西南布依族苗族自治州',
    '黔东南苗族侗族自治州',
    '黔南布依族苗族自治州',
  ],
  云南: [
    '昆明',
    '曲靖',
    '玉溪',
    '保山',
    '昭通',
    '丽江',
    '普洱',
    '临沧',
    '楚雄彝族自治州',
    '红河哈尼族彝族自治州',
    '文山壮族苗族自治州',
    '西双版纳傣族自治州',
    '大理白族自治州',
    '德宏傣族景颇族自治州',
    '怒江傈僳族自治州',
    '迪庆藏族自治州',
  ],
  西藏自治区: ['拉萨', '日喀则', '昌都', '林芝', '山南', '那曲', '阿里地区'],
  陕西: ['西安', '铜川', '宝鸡', '咸阳', '渭南', '延安', '汉中', '榆林', '安康', '商洛'],
  甘肃: [
    '兰州',
    '嘉峪关',
    '金昌',
    '白银',
    '天水',
    '武威',
    '张掖',
    '平凉',
    '酒泉',
    '庆阳',
    '定西',
    '陇南',
    '临夏回族自治州',
    '甘南藏族自治州',
  ],
  青海: [
    '西宁',
    '海东',
    '海北藏族自治州',
    '黄南藏族自治州',
    '海南藏族自治州',
    '果洛藏族自治州',
    '玉树藏族自治州',
    '海西蒙古族藏族自治州',
  ],
  宁夏回族自治区: ['银川', '石嘴山', '吴忠', '固原', '中卫'],
  新疆维吾尔自治区: [
    '乌鲁木齐',
    '克拉玛依',
    '吐鲁番',
    '哈密',
    '昌吉回族自治州',
    '博尔塔拉蒙古自治州',
    '巴音郭楞蒙古自治州',
    '阿克苏地区',
    '克孜勒苏柯尔克孜自治州',
    '喀什地区',
    '和田地区',
    '伊犁哈萨克自治州',
    '塔城地区',
    '阿勒泰地区',
    '石河子',
    '阿拉尔',
    '图木舒克',
    '五家渠',
    '北屯',
    '铁门关',
    '双河',
    '可克达拉',
    '昆玉',
    '胡杨河',
  ],
  台湾: ['台湾省'],
  香港特别行政区: [
    '中西区',
    '湾仔区',
    '东区',
    '南区',
    '油尖旺区',
    '深水埗区',
    '九龙城区',
    '黄大仙区',
    '观塘区',
    '荃湾区',
    '屯门区',
    '元朗区',
    '北区',
    '大埔区',
    '西贡区',
    '沙田区',
    '葵青区',
    '离岛区',
  ],
  澳门特别行政区: [
    '花地玛堂区',
    '花王堂区',
    '望德堂区',
    '大堂区',
    '风顺堂区',
    '嘉模堂区',
    '路凼填海区',
    '圣方济各堂区',
  ],
  China: [
    '北京',
    '天津',
    '河北省',
    '山西省',
    '内蒙古自治区',
    '辽宁省',
    '吉林省',
    '黑龙江省',
    '上海',
    '江苏省',
    '浙江省',
    '安徽省',
    '福建省',
    '江西省',
    '山东省',
    '河南省',
    '湖北省',
    '湖南省',
    '广东省',
    '广西壮族自治区',
    '海南省',
    '重庆',
    '四川省',
    '贵州省',
    '云南省',
    '西藏自治区',
    '陕西省',
    '甘肃省',
    '青海省',
    '宁夏回族自治区',
    '新疆维吾尔自治区',
    '台湾省',
    '香港特别行政区',
    '澳门特别行政区',
    '',
  ],
};
