import React, { Key } from 'react';

import { MEDIA_KEY_ENUM } from '@/constants/const';
import { DouyinFunsContent } from './fans-content/DouyinFunsContent';
import { KuaiShouFunsContent } from './fans-content/KuaiShouFunsContent';
import { ShipingHaoFunsContent } from './fans-content/ShipingHaoFunsContent';
import { XiaoHongShuFunsContent } from './fans-content/XiaoHongShuFunsContent';

type ContentProps = {
  accountId?: Key;
  mediaId?: Key;
};

const FunsCompMap = {
  [MEDIA_KEY_ENUM.DOUYIN]: DouyinFunsContent,
  [MEDIA_KEY_ENUM.KUAISHOU]: KuaiShouFunsContent,
  [MEDIA_KEY_ENUM.WEIXIN]: ShipingHaoFunsContent,
  [MEDIA_KEY_ENUM.XIAOHONGSHU]: XiaoHongShuFunsContent,
};

const Content: React.FC<ContentProps> = ({ accountId, mediaId }) => {
  const FunsComps = FunsCompMap[mediaId as MEDIA_KEY_ENUM];
  return <FunsComps accountId={accountId} />;
};

export default Content;
