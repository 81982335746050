import { AccountAvator } from '@/components/AccountAvator';
import { EmojiImgText } from '@/components/EmojiImg';
import { MEDIA_ID_MAPPING, ROUTE_PATH } from '@/constants/const';
import { AUTH } from '@/constants/permission-code';
import { useAppContext } from '@/contexts/AppContext';
import { dict } from '@/hooks/useChangeLocale';
import { usePermission } from '@/hooks/usePermission';
import useTableRequest, { getColumnSearchProps, getTableColumn } from '@/hooks/useTableRequest';
import { useAvaterMap } from '@/pages/interactive/comment/components/hooks/useAvaterMap';
import { getKeywords } from '@/pages/system/keyword/const.var';
import { downloadComment, getCommentPage } from '@/services/comment';
import type { CommentPageParam } from '@/services/comment/type';
import type { QueryKeywordCategoryItem } from '@/services/keyword_category/type';
import { DateUtils } from '@/utils/dateUtil';
import { Validator } from '@/utils/validator';
import { Space, Spin, Table, Typography } from '@antd';
import { useRequest } from 'ahooks';
import { get, isArray, isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'umi';
const { Paragraph, Link } = Typography;

type ListPros = {
  params: RequestSearchParams;
  category?: QueryKeywordCategoryItem | QueryKeywordCategoryItem[];
  queryUnclassified?: boolean;
};

export const Unclassifyied = 'notMatch';

const List = ({ params, category, queryUnclassified = false }: ListPros) => {
  const navigate = useNavigate();
  const { user } = useAppContext();
  const [downloadParams, setDownloadParams] = useState<RequestSearchParams>();
  const [keyword, setKeyword] = useState('');

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, filters = {}, sorter }) => {
      const { content, title, categoryList } = filters;
      const keyword = get(content, '[0]');

      const queryAll = isArray(category) && isEmpty(categoryList);

      const categoryIds = isArray(category)
        ? (queryAll ? category.map((v) => v.id) : categoryList).join(',')
        : get(category, 'id');

      const sortParams: RequestSortParams = {};
      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = sorter.field;
        sortParams.direction = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
      }
      const downloadParams = {
        ...params,
        ...sortParams,
        queryUnclassified,
        queryAll: queryAll && !queryUnclassified,
        searchWord: keyword,
        title: get(title, '[0]'),
        bid: user?.bid,
        categoryIds,
      };
      setDownloadParams(downloadParams);
      setKeyword(keyword);
      return getCommentPage({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, category, user, queryUnclassified], debounceWait: 200 },
    false,
  );

  const { refresh: download, loading: downloadLoading } = useRequest(
    () => downloadComment(downloadParams as CommentPageParam),
    {
      manual: true,
    },
  );

  const { data: avatorMap } = useAvaterMap(tableProps?.dataSource?.map((v) => v.openId));

  const { getBtnPermissionConfig } = usePermission();

  return (
    <>
      <Space style={{ display: 'flex', marginBlockEnd: 10, justifyContent: 'right' }}>
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      </Space>
      <Table
        {...tableProps}
        columns={
          [
            getTableColumn({
              title: dict('NICKNAME'),
              dataIndex: 'nickName',
              render: (_, record) => <AccountAvator avaterUrl={record?.avatar} name={record?.nickName} />,
            }),
            getTableColumn({
              title: dict('COMMENT_CONTENT'),
              dataIndex: 'content',
              width: 120,
              align: 'left',
              ...getColumnSearchProps({
                dataIndex: 'content',
              }),
              render: (v, record) => (
                <EmojiImgText content={v} searchWord={keyword} keywords={getKeywords(record.keywordMap)} />
              ),
            }),

            isArray(category) && !queryUnclassified
              ? getTableColumn({
                  title: dict('KEYWORD_CLASSIFICATION'),
                  dataIndex: 'categoryList',
                  ellipsis: true,
                  filters: category.map(({ id, name }) => ({ text: name, value: id })),
                  render: (categoryNames) => {
                    return Validator.isNilEmpty(categoryNames) ? '-' : categoryNames.join(',');
                  },
                })
              : null,
            queryUnclassified
              ? null
              : getTableColumn({
                  title: dict('KEYWORD_HIT'),
                  dataIndex: 'keywordList',
                  ellipsis: true,
                  render: (keywordList) => {
                    return Validator.isNilEmpty(keywordList) ? '-' : keywordList.join(',');
                  },
                }),
            getTableColumn({
              title: dict('COMMENT_TIME'),
              dataIndex: 'create_time',
              sorter: true,
              render: (_, r) => DateUtils.formatDateTime(r.createTime),
            }),
            getTableColumn({
              title: dict('WORK'),
              dataIndex: 'cover',
              width: 200,
              render: (url, record) => {
                const detialUrl = `/${ROUTE_PATH.MATRIX}/${ROUTE_PATH.PRODUCTION}/${MEDIA_ID_MAPPING[record.mediaId]}/${
                  ROUTE_PATH.DETAIL
                }/${encodeURIComponent(record.itemId)}`;
                return (
                  <Paragraph
                    ellipsis={{ rows: 3, symbol: 'more' }}
                    title={record.title}
                    onClick={() => navigate(`${detialUrl}?type=comment`)}
                  >
                    <Link {...getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.VIEW)}> {record.title}</Link>
                  </Paragraph>
                );
              },
            }),

            getTableColumn({
              title: dict('ACCOUNT_NAME'),
              dataIndex: 'accountName',
              render: (_, record) => (
                <AccountAvator avaterUrl={get(avatorMap, `${record?.openId}.avatar`)} name={record?.accountName} />
              ),
            }),
            getTableColumn({
              title: dict('AFFILIATION_ORG'),
              dataIndex: 'orgFullName',
              ellipsis: true,
            }),

            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              render: (_, record) => {
                return (
                  <Space>
                    <Link
                      title={dict('ACTION_VIEW_COMMENT')}
                      {...getBtnPermissionConfig(AUTH.MATRIX_OPERATION_ITEM.VIEW)}
                      onClick={() =>
                        navigate(
                          `/${ROUTE_PATH.MATRIX}/${ROUTE_PATH.PRODUCTION}/${MEDIA_ID_MAPPING[record.mediaId]}/${
                            ROUTE_PATH.DETAIL
                          }/${encodeURIComponent(record.itemId)}?type=comment`,
                        )
                      }
                    >
                      {dict('ACTION_VIEW_COMMENT')}
                    </Link>
                  </Space>
                );
              },
            }),
          ].filter((v) => v) as TableCoumn[]
        }
      />
    </>
  );
};

export default List;
