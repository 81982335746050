import { useAppContext } from '@/contexts/AppContext';
import { getAccountAnalysis } from '@/services/account';
import { ProCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import { MEDIA_KEY_ENUM, headerFormCardProps } from '@/constants/const';
import RateBarChart from '../charts/RateBarChart';
import AgePieChart from '../charts/AgePieChart';
import GenderPieChart from '../charts/GenderPieChart';
import { dict } from '@/hooks/useChangeLocale';

//快手粉丝
export const KuaiShouFunsContent = ({ accountId }) => {
  const { user } = useAppContext();

  //加载用户分析相关数据
  const { data: userAnalysisData, loading } = useRequest(
    () => getAccountAnalysis(accountId, user?.bid, MEDIA_KEY_ENUM.KUAISHOU),
    {
      refreshDeps: [user?.bid, accountId],
    },
  );

  return (
    <ProCard.Group gutter={[16, 16]} wrap loading={loading}>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('GENDER_DISTRIBUTION')}>
        {/* <RateBarChart data={userAnalysisData} type="gender" calculateRate /> */}
        <GenderPieChart data={userAnalysisData} showLabelRate />
      </ProCard>

      <ProCard {...headerFormCardProps} colSpan={12} title={dict('AGE_DISTRIBUTION')}>
        {/* <RateBarChart data={userAnalysisData} type="age" calculateRate /> */}
        <AgePieChart data={userAnalysisData} showLabelRate />
      </ProCard>

      <ProCard {...headerFormCardProps} colSpan={12} title={dict('REGION_REPORT_DISTRIBUTION_TOP')}>
        <RateBarChart data={userAnalysisData} type="city" valueType="rate" topCount={10} />
      </ProCard>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('INTEREST_REPORT_DISTRIBUTION_TOP')}>
        <RateBarChart data={userAnalysisData} type="interest" valueType="rate" topCount={10} />
      </ProCard>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('ACTIVE_TIME')}>
        <RateBarChart data={userAnalysisData} type="active" calculateRate />
      </ProCard>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('SYSTEM_DISTRIBUTION')}>
        <RateBarChart data={userAnalysisData} type="system" calculateRate />
      </ProCard>
    </ProCard.Group>
  );
};
