import { useAppContext } from '@/contexts/AppContext';
import { getAccountAnalysis } from '@/services/account';
import { ProCard } from '@ant-design/pro-components';
import { useRequest } from 'ahooks';
import AgePieChart from '../charts/AgePieChart';
import GenderPieChart from '../charts/GenderPieChart';
import SystemPieChart from '../charts/SystemPieChart';
import { MEDIA_KEY_ENUM, headerFormCardProps } from '@/constants/const';
import { Col, Row } from '@antd';
import { CityTable, ProvienceTable } from '../tables/RegionTable';
import { dict } from '@/hooks/useChangeLocale';

//视频号粉丝
export const ShipingHaoFunsContent = ({ accountId }) => {
  const { user } = useAppContext();

  //加载用户分析相关数据
  const { data: userAnalysisData, loading } = useRequest(
    () => getAccountAnalysis(accountId, user?.bid, MEDIA_KEY_ENUM.WEIXIN),
    {
      refreshDeps: [user?.bid, accountId],
    },
  );

  return (
    <>
      <ProCard.Group gutter={[16, 16]} wrap loading={loading}>
        <ProCard {...headerFormCardProps} colSpan={12} title={dict('GENDER')}>
          <GenderPieChart data={userAnalysisData} showLabelRate />
        </ProCard>

        <ProCard {...headerFormCardProps} colSpan={12} title={dict('ACCESS_DEVICES')}>
          <SystemPieChart data={userAnalysisData} type="system" />
        </ProCard>

        <ProCard {...headerFormCardProps} colSpan={12} title={dict('AGE_DISTRIBUTION')}>
          <AgePieChart data={userAnalysisData} />
        </ProCard>
      </ProCard.Group>
      <Row gutter={[16, 0]} style={{ margin: 0 }}>
        <Col span={12}>
          <ProvienceTable diminDatas={userAnalysisData} />
        </Col>
        <Col span={12}>
          <CityTable diminDatas={userAnalysisData} />
        </Col>
      </Row>
    </>
  );
};
