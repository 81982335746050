import { MEDIA_KEY_ENUM, headerFormCardProps } from '@/constants/const';
import { useAppContext } from '@/contexts/AppContext';
import { getAccountAnalysis } from '@/services/account';
import { ProCard } from '@ant-design/pro-components';
import { Radio } from '@antd';
import { useRequest, useSafeState } from 'ahooks';
import { FunsWordCloud } from '../charts/FunsWordCloud';
import GenderPieChart from '../charts/GenderPieChart';
import TopBarChart from '../charts/TopBarChart';
import { getHomeFunsTrand } from '@/services/home';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { DateUtils } from '@/utils/dateUtil';
import XhsFunsTrendLineChart from '../charts/FunsTrendLineChart';
import AudienceSourePieChart from '../charts/AudienceSourePieChart';
import RateBarChart from '../charts/RateBarChart';
import { dict } from '@/hooks/useChangeLocale';

//小红书粉丝画像
export const XiaoHongShuFunsContent = ({ accountId }) => {
  const { user } = useAppContext();
  const [chartTrendDays, setChartTrendDays] = useSafeState('7');

  //加载用户分析相关数据
  const { data: userAnalysisData, loading: userAnalysisDataLoading } = useRequest(
    async () => getAccountAnalysis(accountId, user?.bid, MEDIA_KEY_ENUM.XIAOHONGSHU),
    {
      refreshDeps: [user?.bid, accountId],
    },
  );

  const { data: chartData } = useRequest(
    async () => {
      const endDay = dayjs();
      const startDay = endDay.subtract(Number(chartTrendDays), 'day');

      const [startDate, endDate] = [startDay, endDay].map((d) => DateUtils.format(d));
      return getHomeFunsTrand({
        accountId,
        bid: get(user, 'bid'),
        startDate,
        endDate,
        mediaId: MEDIA_KEY_ENUM.XIAOHONGSHU,
      });
    },
    {
      refreshDeps: [user?.bid, accountId, chartTrendDays],
    },
  );

  return (
    <ProCard.Group gutter={[16, 16]} wrap loading={userAnalysisDataLoading}>
      <ProCard
        {...headerFormCardProps}
        title={dict('DATA_TRANDS')}
        bodyStyle={{ minHeight: 500 }}
        extra={
          <Radio.Group
            size="small"
            value={chartTrendDays}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => setChartTrendDays(e.target.value)}
            options={[
              { label: dict('LAST_NUM_DAYS', { num: 7 }), value: '7' },
              { label: dict('LAST_NUM_DAYS', { num: 30 }), value: '30' },
            ]}
          />
        }
      >
        <XhsFunsTrendLineChart data={chartData} />
      </ProCard>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('GENDER_DISTRIBUTION')}>
        <GenderPieChart data={userAnalysisData} showLabelRate valueEqRate />
      </ProCard>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('AGE_DISTRIBUTION')}>
        {/* <AgePieChart data={userAnalysisData} showLabelRate valueEqRate /> */}
        <RateBarChart data={userAnalysisData} type="age" />
      </ProCard>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('INTEREST_DISTRIBUTION')}>
        <FunsWordCloud data={userAnalysisData} />
      </ProCard>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('FUNS_SOURCE')}>
        <AudienceSourePieChart data={userAnalysisData} />
      </ProCard>
      <ProCard {...headerFormCardProps} colSpan={12} title={dict('GENDER_DISTRIBUTION')}>
        <TopBarChart diminDatas={userAnalysisData} hideValue type="city" />
      </ProCard>
    </ProCard.Group>
  );
};
