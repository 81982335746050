import ChartCore from '@/components/ChartCore';
import { DATE_FORMAT_STR, DateUtils } from '@/utils/dateUtil';
import { Spin } from '@antd';
import { useRequest } from 'ahooks';
import { useAppContext } from '@/contexts/AppContext';
import { getLiveStreamTrend } from '@/services/live_stream/all';
import { MEDIA_KEY_ENUM } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

const getOption = (data, labels, indicatorOptions, format = 'HH:mm') => {
  return {
    tooltip: {
      trigger: 'axis',
    },
    legend: {},
    grid: {
      top: 30,
      left: 50,
      right: 50,
      bottom: 60,
    },
    dataZoom: data.length > 10 ? [{ maxValueSpan: 15, minValueSpan: 8, start: 90 }] : undefined,
    xAxis: {
      type: 'category',
      boundaryGap: false,
      offset: 10,
      data: labels,
      axisLabel: {
        formatter: function (value) {
          return `${DateUtils.format(value, format)}`;
        },
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: '{value} ',
      },
    },
    series: indicatorOptions.map(({ label, value }) => ({
      name: label,
      // smooth: true,
      areaStyle: {
        opacity: 0,
      },
      type: 'line',
      data: data.map((v) => v[value]),
    })),
  };
};

export const KuaiShouAccountReportLiveLineChart = ({
  accountId,
  startDate,
  endDate,
}: {
  accountId: Key;
  startDate?: string;
  endDate?: string;
}) => {
  const { user } = useAppContext();

  const indicatorOptions = [
    {
      label: dict('LIVE_STATIST_LIVE_NUM'), //直播场次
      value: 'liveTotal',
    },
    {
      label: dict('LIVE_INDICATOR_WATCH_UV'), //观看人数
      value: 'lpScreenLiveWatchUv',
    },
    {
      label: dict('LIVE_INDICATOR_LIKE_COUNT'), //点赞次数
      value: 'lpScreenLiveLikeCount',
    },
    {
      label: dict('LIVE_INDICATOR_COMMENT_COUNT'), //评论次数
      value: 'lpScreenLiveCommentCount',
    },
    {
      label: dict('LIVE_INDICATOR_SHARE_COUNT'), // 分享次数
      value: 'lpScreenLiveShareCount',
    },
  ];

  const { data = {}, loading } = useRequest(
    async () => {
      const list = await getLiveStreamTrend({
        accountId,
        bid: user?.bid,
        startDate,
        endDate,
        mediaId: MEDIA_KEY_ENUM.KUAISHOU,
      });

      return list.reduce((obj, cur) => {
        obj[DateUtils.format(cur['liveDate'], DATE_FORMAT_STR)] = cur;
        return obj;
      }, {});
    },
    { refreshDeps: [accountId, startDate, endDate, user?.bid] },
  );

  return (
    <Spin spinning={loading}>
      <ChartCore
        option={getOption(Object.values(data), Object.keys(data), indicatorOptions, DATE_FORMAT_STR)}
        notMerge
        style={{ height: 400 }}
      />
    </Spin>
  );
};
