import { dict } from '@/hooks/useChangeLocale';
import { Format } from '@/utils/format';
import { sum } from 'lodash';

type ChartDataType = { value: number; name: string };

export const getConvertAgeDimensionsName = (dimension: string) => {
  if (dimension == 'unknow') return dict('UNKNOWN');
  if (dimension.includes('岁')) return dimension;
  if (dimension.startsWith('fage_'))
    return dict('NUM_AGE', { num: dimension.replace('fage_', '').replace('_+', '+').replace('_', '-') });
  if (dimension.includes('-'))
    return dimension.endsWith('-')
      ? dict('OVER_NUM_AGE', { num: dimension.replace('-', '') })
      : dict('NUM_AGE', { num: dimension });
  if (dimension.includes('<')) return dict('NUM_AGE', { num: `${dimension.replace('<', '0-')}` });
  if (dimension.includes('>')) return dict('OVER_NUM_AGE', { num: dimension.replace('>', '') });

  return dimension;
};

export const getPieChartOptions = (data: ChartDataType[], showRate = false, valueEqRate = false) => {
  const total = sum(data.map((v) => v.value));
  return {
    legend: {
      left: 'center',
    },
    tooltip: {
      formatter: valueEqRate ? '{b}： {c}%' : `{b}： {c} ${dict('PEOPLE')} ({d}%)`,
    },
    title: valueEqRate
      ? undefined
      : {
          text: dict('FANS_COUNT'), //粉丝总数
          subtext: Format.toAmountNumber(total),
          left: 'center',
          top: 'center',
        },

    series: [
      {
        type: 'pie',
        radius: ['40%', '60%'],
        label: {
          show: true,
          formatter: showRate ? (valueEqRate ? ' {c}%' : '{b} ({d}%)') : `{b} {c}${dict('PEOPLE')}`,
        },
        data,
      },
    ],
  };
};
