import { MenuToggle } from '@/icons';
import { ProLayout } from '@ant-design/pro-components';
import type { ProLayoutProps } from '@ant-design/pro-components';
import { Tooltip } from '@antd';
import { useBoolean } from 'ahooks';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'umi';
import { Menu, getMenus } from '../../const/config.var';
import { themeConfig, theme } from '../../const/themes';
import style from './HorizontalLayout.less';
import { dict } from '@/hooks/useChangeLocale';
import { Function1, isEmpty } from 'lodash';
import { AuthKey } from '@/constants/permission-code';
import { useAppContext } from '@/contexts/AppContext';

const filterMenus = (hasPermission: Function1<AuthKey | AuthKey[], boolean>, menus: Menu[] | undefined) => {
  if (!menus || isEmpty(menus)) return undefined;
  return [...menus].filter((v) => (v.permissionKey ? hasPermission(v.permissionKey as AuthKey) : true));
};
const headerTheme = {
  ...theme,
  colorBgAppListIconHover: 'rgba(0,0,0,0.06)',
  colorTextAppListIcon: 'rgba(255,255,255,0.85)',
  sider: {
    colorMenuBackground: themeConfig.colorPrimary,
    // colorBgMenuItemCollapsedElevated: 'rgba(0,0,0,0.85)',
    colorMenuItemDivider: 'rgba(255,255,255,0.15)',
    colorBgMenuItemHover: 'rgba(0,0,0,0.06)',
    colorTextMenuSelected: themeConfig.colorPrimary,
    colorTextMenuItemHover: 'rgba(255,255,255,0.75)',
    colorTextMenu: 'rgba(255,255,255,0.75)',
    colorTextMenuSecondary: 'rgba(255,255,255,0.65)',
    colorTextMenuTitle: 'rgba(255,255,255,0.95)',
    colorTextMenuActive: 'rgba(255,255,255,0.95)',
    colorTextSubMenuSelected: '#fff',
  },
};
export const HorizontalLayout = ({ children, header, ...rest }: ProLayoutProps & { header: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasPermission, platforms } = useAppContext();

  const [toggleCollapsedMenu, { toggle: collapsedMenuToggle }] = useBoolean(true);
  const collapsedMenu = themeConfig.allNavMenuCollapsed && toggleCollapsedMenu;

  const appName = dict('APP_NAME');
  return (
    <ProLayout
      token={headerTheme}
      className={classNames({ [style['collapsed-menu']]: collapsedMenu })}
      menuHeaderRender={(logo: ReactNode, title: ReactNode) => {
        return (
          <div className={style['app-name']}>
            {logo}
            <span title={appName}>{appName.length > 8 ? 'SCGM' : appName}</span>
          </div>
        );
      }}
      siderWidth={160}
      route={{ path: '/', routes: filterMenus(hasPermission, getMenus(platforms)) }}
      collapsed={collapsedMenu}
      collapsedButtonRender={(collapsed) => {
        return themeConfig.allNavMenuCollapsed ? (
          <Tooltip placement="rightBottom" title={collapsed ? dict('TURN_ON_NAVIGATION') : dict('TURN_OFF_NAVIGATION')}>
            <MenuToggle
              className={classNames({ [style['collapsed-action']]: collapsed })}
              rotate={collapsed ? 180 : 0}
              onClick={collapsedMenuToggle}
            />
          </Tooltip>
        ) : null;
      }}
      location={location}
      disableMobile
      menuItemRender={({ path, splitLine }, dom) => {
        return (
          <>
            <div
              className={classNames(style['silder-menu-item'], {
                [style['silder-menu-item-splitline']]: splitLine,
              })}
              onClickCapture={() => {
                navigate(path as string);
              }}
            >
              {dom}
            </div>
          </>
        );
      }}
      {...rest}
    >
      {header}
      {children}
    </ProLayout>
  );
};
