import { AccountAvator } from '@/components/AccountAvator';
import { EmojiImgText } from '@/components/EmojiImg';
import { SearchInput } from '@/components/Form/Field/SearchInput';
import { dict } from '@/hooks/useChangeLocale';
import { getKeywords } from '@/pages/system/keyword/const.var';
import { getConversationDetailPage } from '@/services/conversation';
import type {
  ConversationDetailPageVO,
  ConversationDetailParam,
  ConversationPageVO,
} from '@/services/conversation/type';
import { getPoiByid } from '@/services/poi';
import { DateUtils } from '@/utils/dateUtil';
import { Descriptions, Divider, Drawer, DrawerProps, Layout, Radio, Spin } from '@antd';
import { useAsyncEffect, useRequest, useSafeState, useSize } from 'ahooks';
import type { Property } from 'csstype';
import { isEmpty, isNil, isString, omit, reverse, sum } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import style from './index.less';

const { Sider, Content } = Layout;

type DrawerDetailProps = {
  record: ConversationPageVO;
} & DrawerProps;

type DataTimeSection = {
  dateTime?: string;
  data: ConversationDetailPageVO;
};

const MODE_DEFAULT = 'ONCE';
const PAGE_INFO_DEFAULT = {
  pageIndex: 1,
  pageSize: 20,
  total: Number.MAX_SAFE_INTEGER,
  totalPage: 1,
};

//https://ccdy.yuque.com/uo9tgf/rubuwq/ft85rif922d9wnsc
const formatConversation = (obj) => {
  try {
    if (obj.leads_cmd == 'empty') return `[${dict('NO_NOME_INFOMATION')}]`;
    if (obj.fallback_tips) return `[${obj.fallback_tips}]`;
    if (obj.tips) return `[${obj.tips}]`;
    if (obj.text) return obj.text;
    if (obj.content) {
      const { content } = obj;
      if (isString(content)) return content.split('\n')[0];
      if (content.originContent) return content.originContent;
      if (content.content) {
        return (content.msg ? `[${content.msg}]` : '') + content.content;
      }
      if (content.msg) return `[${content.msg}]`;
      if (content.guest_detail_info && content.talk_num)
        return `[${dict('CUSTOMER_READY_TALK_NO')}：${content.talk_num}]`;
      if (content.talk_num) return `[${dict('SELLER_FINISH_TALK_NO')}：${content.talk_num}]`;
    }
    if (obj.description) return obj.description;
    if (obj.content_title && obj.itemId) return `[${dict('SHARE_VIDEO')}]${obj.content_title}`;
    if (obj.item_title && obj.itemId) return `[${dict('SHARE_VIDEO')}]${obj.item_title}`;
    if (obj.cmd == 'cs_awe_emoji') return `[${dict('SEND_EMOJI')}]`;
    if (obj.emoji_from && obj.display_name) return `[${dict('SEND_EMOJI')}]${obj.display_name}`;
    if (obj.push_detail && obj.title) return obj.push_detail + obj.title;
    if (obj.message_hint) return `[${obj.message_hint}]`;
    if (obj.itemId) return `[${dict('SHARE_VIDEO')}]`;
    if (obj.title) return `[${dict('SHARE')}]${obj.title}`;

    return dict('CONVERSATION_NOT_SUPPORT_TIP');
  } catch (error) {}
};

export const getContentText = (record) => {
  const { type } = record;
  const { contentText, content } = record;
  if (isEmpty(contentText)) {
    const obj = JSON.parse(content);
    if (type == 21) {
      //直播卡片;
      return dict('CONVERSATION_CARD_TIP');
    }
    return formatConversation(obj);
  }

  return contentText;
};

const DrawerDetail: React.FC<DrawerDetailProps> = ({ record, ...props }) => {
  const { open } = props;
  const [mode, setMode] = useState<'ALL' | 'ONCE'>(MODE_DEFAULT);
  const [searchWord, setSearchWord] = useState('');
  const [dataList, setDataList] = useSafeState<DataTimeSection[]>([]);
  const [loading, setLoading] = useSafeState<boolean>(false);
  const [pageInfo, setPageInfo] = useSafeState<PageInfo>({
    ...PAGE_INFO_DEFAULT,
  });

  const contentRef = useRef<HTMLDivElement>(null);
  const contentSize = useSize(contentRef);
  const infiniteRef = useRef<AnyObject>(null);
  const [infiniteJustifyContent, setInfiniteJustifyContent] = useSafeState<Property.JustifyContent>('flex-end');

  const [queryParams] = useState<ConversationDetailParam>({
    conversationShortId: record?.conversationShortId,
    sessionId: record.id,
    accountId: record.accountId,
    uniqueId: record.uniqueId,
  });

  const { data: poiData } = useRequest(
    async () => {
      if (!record.poiId) {
        return null;
      }
      return getPoiByid(record.poiId);
    },
    { refreshDeps: [record.poiId] },
  );

  const loadData = async ({ pageIndex }: { pageIndex: number }) => {
    let params = {
      pageIndex,
      pageSize: pageInfo.pageSize,
      ...queryParams,
      searchWord,
      sort: 'createTime',
      direction: 'DESC',
    };

    if (mode == 'ALL') {
      params = omit(params, ['sessionId', 'conversationShortId']);
    } else {
      params = omit(params, ['accountId', 'uniqueId']);
    }

    try {
      setLoading(true);
      const { list, pageInfo: page } = await getConversationDetailPage(params);
      let _list: DataTimeSection[] = list.map((item) => {
        const { createTime } = item;
        const data = {
          ...item,
          createTime: DateUtils.formatDateTime(createTime),
          contentText: getContentText(item),
        };
        return { data };
      });
      if (pageIndex > 1) {
        _list = [...dataList, ..._list];
      }
      _list = reverse(_list);

      let lastSessionId: number | undefined;
      _list.forEach((item) => {
        item.dateTime = undefined;
        if (isNil(lastSessionId) || lastSessionId != item.data.sgmConversationId) {
          lastSessionId = item.data.sgmConversationId;
          item.dateTime = item.data.createTime;
        }
      });

      _list = reverse(_list);

      setDataList(_list);
      setPageInfo(page);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setDataList([]);
    if (!open) {
      setMode(MODE_DEFAULT);
      setPageInfo({ ...PAGE_INFO_DEFAULT });
      setSearchWord('');
      return;
    }
    loadData({ pageIndex: 1 });
  }, [searchWord, open, mode]);

  const fetchMoreData = async () => {
    return await loadData({
      pageIndex: pageInfo.pageIndex + 1,
    });
  };

  useAsyncEffect(async () => {
    const el = infiniteRef?.current?.el;
    if (!el) {
      return;
    }
    setInfiniteJustifyContent(
      sum([...el.childNodes].map((e) => e.clientHeight)) > el.clientHeight ? 'flex-start' : 'flex-end',
    );
  }, [infiniteRef.current, dataList.length]);

  return (
    <Drawer
      {...props}
      title={dict('CONVERSATION_INFO')}
      placement="right"
      width={1000}
      className={style['session-comment-drawer']}
    >
      <Layout>
        <Layout>
          <div>
            <Radio.Group defaultValue={mode} size="middle" onChange={(e) => setMode(e.target.value)}>
              <Radio.Button value="ONCE">{dict('CONVERSATION_CURRENT')}</Radio.Button>
              <Radio.Button value="ALL">{dict('CONVERSATION_ALL')}</Radio.Button>
            </Radio.Group>

            <SearchInput
              onKeywordChange={setSearchWord}
              style={{ width: 200 }}
              placeholder={dict('CONVERSATION_SEARCH_TIP')}
            />
          </div>

          <Content ref={contentRef}>
            {(isEmpty(dataList) && loading) || contentSize == null ? (
              <div
                style={{ height: contentSize?.height, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Spin spinning />
              </div>
            ) : (
              <div
                id="content-scrollable"
                style={{
                  height: contentSize?.height,
                  overflow: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                }}
              >
                <InfiniteScroll
                  ref={infiniteRef}
                  height={contentSize.height}
                  dataLength={dataList.length}
                  next={fetchMoreData}
                  style={{ display: 'flex', flexDirection: 'column-reverse', justifyContent: infiniteJustifyContent }}
                  hasMore={pageInfo.pageIndex * pageInfo.pageSize < pageInfo.total}
                  inverse
                  loader={
                    <div className={style['content-scrollable-loader']}>
                      <Spin spinning />
                    </div>
                  }
                  scrollableTarget="content-scrollable"
                >
                  {dataList.map((dataItem) => {
                    const { data, dateTime } = dataItem;
                    const isFromMe = record.userId != data.sender;
                    const className = isFromMe ? style['reply'] : undefined;
                    const avatarName = (isFromMe ? record.originalEnterpriseName : record.guestName) ?? '';
                    const avatarUrl = isFromMe ? record.avatar : record.guestAvatarUrl;
                    if (!data.contentText) return '';
                    return (
                      <div key={data.id} style={{ display: 'block', position: 'relative' }}>
                        {dateTime && <span className={style['time']}>{dateTime}</span>}

                        <div className={style['comment-panel']}>
                          <div className={className}>
                            <AccountAvator avaterUrl={avatarUrl} name={avatarName} showName={false} />
                            <div className={style['msg']}>
                              {isString(data.contentText) ? (
                                <EmojiImgText
                                  content={data.contentText ?? ''}
                                  searchWord={searchWord}
                                  keywords={getKeywords(record.keywordMap)}
                                />
                              ) : (
                                data.contentText
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
            )}
          </Content>
        </Layout>
        <Sider>
          <div className={style['account-info']}>
            <Descriptions
              title={<AccountAvator name={record.originalEnterpriseName ?? ''} avaterUrl={record.avatar} />}
              column={1}
            >
              <Descriptions.Item label={dict('TIKTOK_ACCOUNT')}>{record.accountUniqueId}</Descriptions.Item>
              <Descriptions.Item label={dict('AFFILIATION_ORG')}>{record.orgFullName}</Descriptions.Item>
              <Descriptions.Item label={dict('MATRIX_ACCOUNT_NAME')}>{record.accountName}</Descriptions.Item>
              <Descriptions.Item label={dict('ADDRESS')}>
                {poiData == null
                  ? '-'
                  : [poiData.province, poiData.city, poiData.district, poiData.address]
                      .filter((m) => !isEmpty(m))
                      .join('')}
              </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Descriptions
              title={<AccountAvator name={record.guestName ?? ''} avaterUrl={record.guestAvatarUrl} />}
              column={1}
            >
              <Descriptions.Item label={dict('TIKTOK_ACCOUNT')}>{record.uniqueId}</Descriptions.Item>
            </Descriptions>
            <Divider />
          </div>
        </Sider>
      </Layout>
    </Drawer>
  );
};

export default DrawerDetail;
