import { AccountAvator } from '@/components/AccountAvator';
import { FormCard } from '@/components/PageCard';
import { useAddTagModal } from '@/components/modal/AddTagModal';
import { useCollectMaterialModal } from '@/components/modal/useCollectCaseModal';
import { MEDIA_ID_MAPPING, MEDIA_KEY_ENUM, ROUTE_PATH } from '@/constants/const';
import { AUTH } from '@/constants/permission-code';
import { useAppContext } from '@/contexts/AppContext';
import { useTagColumns } from '@/hooks/tag/useTagColumn';
import { dict } from '@/hooks/useChangeLocale';
import { usePermission } from '@/hooks/usePermission';
import useTableRequest, { getTableColumn } from '@/hooks/useTableRequest';
import { getSoretParams } from '@/pages/live/const.var';
import { LiveIndicatorTitle } from '@/pages/live/report/components/LiveIndicatorTitle';
import { downloadLiveStream, getLiveStreamPages, updateLiveTag } from '@/services/live_stream/all';
import { DateUtils } from '@/utils/dateUtil';
import { BarChartOutlined, CommentOutlined, HeartOutlined, PlaySquareOutlined, TagsOutlined } from '@ant-design/icons';
import { App, Space, Spin, Table, Typography } from '@antd';
import { useCreation, useRequest, useSafeState } from 'ahooks';
import { camelCase } from 'lodash-es';

const { Link } = Typography;

type ListPros = {
  params: RequestSearchParams;
};
const List = ({ params }: ListPros) => {
  const { getEnum, user } = useAppContext();
  const { message } = App.useApp();
  const [downloadParams, setDownloadParams] = useSafeState<RequestSearchParams>({});
  const liveStreamSortEnums = getEnum('LiveStreamSortParamEnum');
  const liveStreamSortFieldMap = useCreation(
    () =>
      liveStreamSortEnums.reduce((map, cur) => {
        const { name } = cur;
        const [, fieldName] = name.split('.');
        map[camelCase(fieldName)] = name;
        return map;
      }, {}),
    [liveStreamSortEnums],
  );

  const { refresh: download, loading: downloadLoading } = useRequest(() => downloadLiveStream(downloadParams), {
    manual: true,
  });

  const { tableProps, refresh } = useTableRequest(
    ({ pageIndex, pageSize, sorter = { field: 'liveStartTime', order: 'descend' } }) => {
      const downloadParams = {
        ...params,
        tagValueIds: params.tagValueIds?.map((v) => v[1]),
        ...getSoretParams(sorter, liveStreamSortFieldMap),
      };
      setDownloadParams(downloadParams);
      return getLiveStreamPages({
        ...downloadParams,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params], debounceWait: 500 },
  );

  const { run: updateTag, loading: updateTagLoading } = useRequest(
    async (itemId, tagIds) => {
      try {
        await updateLiveTag(itemId, tagIds, user?.bid);
        message.success(dict('OPERATION_SUCCESS'));
        refresh();
      } catch (error) {
        message.error(dict('OPERATION_FAILURE'));
      }
    },
    {
      refreshDeps: [user],
      manual: true,
    },
  );

  const [AddTagModal, showTagModal] = useAddTagModal();
  const [CollectMaterialModal, collectLoading, showCollectMaterialModal] = useCollectMaterialModal();
  const ROUTE_REPORT_PATH = `/${ROUTE_PATH.LIVE}/${ROUTE_PATH.REPORT}`;

  const { getBtnPermissionConfig } = usePermission();
  const hasTags = !getBtnPermissionConfig(AUTH.TAG_MANAGEMENT.PAGE)?.disabled;
  const [tagColumns] = useTagColumns();

  return (
    <FormCard
      title={dict('LIVE_DATA')}
      style={{ marginBottom: 10 }}
      extra={
        <Spin spinning={downloadLoading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.LIVE_STREAM.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      }
    >
      <Table
        {...tableProps}
        loading={tableProps.loading || updateTagLoading || collectLoading}
        scroll={{ x: 2400, y: 500 }}
        columns={
          [
            getTableColumn({ title: dict('DEALER'), dataIndex: 'distributorName', fixed: 'left' }),
            getTableColumn({ title: dict('DEALER_CODE'), dataIndex: 'distributorCode', fixed: 'left' }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenClueUv" />, //全场景线索人数
              dataIndex: 'lpScreenClueUv',
              width: 180,
              fixed: 'left',
              sorter: true,
            }),
            // getTableColumn({ title: dict('LIVE_ROOM_ID'), dataIndex: 'roomId' }),
            getTableColumn({
              title: dict('LIVE_ACCOUNT'),
              dataIndex: 'name',
              width: 150,
              render: (_, record) => <AccountAvator avaterUrl={record?.avatarUrl} name={record?.name} />,
            }),
            getTableColumn({ title: dict('AFFILIATION_PLATFORM'), dataIndex: 'mediaName' }),
            getTableColumn({ title: dict('REGION_AREA'), dataIndex: 'regionName' }),

            // getTableColumn({ title: dict('STORE'), dataIndex: 'storeName' }),
            // getTableColumn({ title: dict('EMPLOYEE'), dataIndex: 'staffName' }),
            // getTableColumn({ title: dict('INFLUENCER'), dataIndex: 'influenceName' }),

            // hasTags
            //   ? getTableColumn({
            //       title: dict('TAG'),
            //       dataIndex: 'tagInfoList',
            //       width: 200,
            //       ellipsis: true,
            //       render: (tagInfoList) =>
            //         isEmpty(tagInfoList) ? '-' : tagInfoList?.map((v) => `${v.tagName}-${v.tagValueName}`).join(','),
            //     })
            //   : null,
            ...(tagColumns as any[]),
            getTableColumn({
              title: dict('LIVE_TIME'),
              dataIndex: 'liveStartTime',
              sorter: true,
              defaultSortOrder: 'descend',
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn({
              title: dict('LIVE_END_TIME'),
              dataIndex: 'liveEndTime',
              sorter: true,
              render: (v) => DateUtils.formatDateTime(v),
            }),

            getTableColumn({
              title: dict('LIVE_DURATION'),
              dataIndex: 'liveDuration',
              sorter: true,
              render: (v) => DateUtils.formatDiffrenceTime(v),
            }),

            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveWatchUv" />, //累计观看人数
              dataIndex: 'lpScreenLiveWatchUv',
              width: 150,
              sorter: true,
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveCommentCount" />, //评论次数
              dataIndex: 'lpScreenLiveCommentCount',
              width: 150,
              sorter: true,
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveShareCount" />, //分享次数
              dataIndex: 'lpScreenLiveShareCount',
              width: 150,
              sorter: true,
            }),
            getTableColumn({
              title: <LiveIndicatorTitle type="lpScreenLiveLikeCount" />, //点赞次数
              dataIndex: 'lpScreenLiveLikeCount',
              width: 150,
              sorter: true,
            }),

            getTableColumn({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              width: 150,
              render: (_, record) => {
                const { roomId, mediaId } = record;
                const isKuaishou = mediaId === MEDIA_KEY_ENUM.KUAISHOU;
                const isXiaohongshu = mediaId === MEDIA_KEY_ENUM.XIAOHONGSHU;
                const isDouyin = mediaId == MEDIA_KEY_ENUM.DOUYIN;
                const url = `${ROUTE_REPORT_PATH}/${MEDIA_ID_MAPPING[mediaId]}/${ROUTE_PATH.DETAIL}/${roomId}`;

                const authConfig = getBtnPermissionConfig(AUTH.LIVE_STREAM.VIEW);
                const addfavourAuthConfig = getBtnPermissionConfig(AUTH.MATERIAL_MANAGEMENT.CREATE);
                return (
                  <Space>
                    <Link onClick={() => window.open(`${BASE_PATH}${url}?type=data`)} {...authConfig}>
                      <BarChartOutlined className="action-icon" title={dict('ACTION_REPORT')} />
                    </Link>
                    <Link
                      disabled={isKuaishou}
                      onClick={() => window.open(`${BASE_PATH}${url}?type=barrage`)}
                      {...authConfig}
                    >
                      <CommentOutlined className="action-icon" title={dict('BARRAGE')} />
                    </Link>
                    <Link
                      disabled={isKuaishou}
                      onClick={() => window.open(`${BASE_PATH}${url}?type=playhistory`)}
                      {...authConfig}
                    >
                      <PlaySquareOutlined className="action-icon" title={dict('ACTION_REPLAY')} />
                    </Link>
                    {hasTags && (
                      <Link
                        title={dict('ACTION_ADD_NAME', { name: dict('TAG') })}
                        disabled={isKuaishou || isXiaohongshu}
                        onClick={() => {
                          showTagModal(
                            record?.tagInfoList?.filter((v) => v.type == 'manual').map((v) => [v.tagId, v.tagValId]),
                            record?.tagInfoList?.filter((v) => v.type == 'automatic').map((v) => [v.tagId, v.tagValId]),
                            record.roomId,
                          );
                        }}
                      >
                        <TagsOutlined className="action-icon" title={dict('ACTION_ADD_NAME', { name: dict('TAG') })} />
                      </Link>
                    )}
                    <Link
                      title={
                        record.topCase
                          ? dict('JOIN_EXCELLENT_CASE_ALREADY')
                          : isDouyin
                            ? dict('JOIN_EXCELLENT_CASE')
                            : dict('NOT_CURRENTLY_SUPPORTED')
                      }
                      disabled={record.topCase || !isDouyin}
                      {...addfavourAuthConfig}
                      onClick={() => showCollectMaterialModal(record.roomId, 'live', MEDIA_KEY_ENUM.DOUYIN)}
                    >
                      <HeartOutlined className="action-icon" />
                    </Link>
                  </Space>
                );
              },
            }),
          ].filter(Boolean) as TableCoumn[]
        }
      />
      <AddTagModal
        onTagSelect={(tagIds, targetId) => {
          updateTag(
            targetId,
            tagIds.map((v) => v[1]),
          );
        }}
      />
      <CollectMaterialModal onCollectedOk={refresh} />
    </FormCard>
  );
};

export default List;
