import ChartCore from '@/components/ChartCore';
import { dict } from '@/hooks/useChangeLocale';
import type { HomeUserAnalysisResult } from '@/services/home/type';
import { useCreation } from 'ahooks';
import { isEmpty } from 'lodash';

export const AudienceSourePieChart = ({ data }: { data?: HomeUserAnalysisResult[] }) => {
  const list = useCreation(() => {
    return data
      ?.filter((v) => ['fansSource'].includes(v.audienceCharacterType))
      .map((v) => {
        return {
          name: v.dimensions,
          value: v.metrics,
        };
      })
      .sort((v1, v2) => (v1.name > v2.name ? 1 : -1));
  }, [data]);

  return (
    <ChartCore
      empty={isEmpty(list)}
      option={{
        legend: {
          left: 'center',
        },
        tooltip: {
          formatter: '{b}： {c}%',
        },

        series: [
          {
            name: dict('LIVE_STATIST_AUDIENCE_TRAFFIC_SOURCE'), // 观众流量来源
            type: 'pie',
            radius: ['40%', '60%'],
            label: {
              show: true,
              formatter: '{b}\n{d}%',
            },
            data: list,
          },
        ],
      }}
      notMerge
      style={{ height: 300 }}
    />
  );
};

export default AudienceSourePieChart;
