import { AccountAvator } from '@/components/AccountAvator';
import { EmojiImgText } from '@/components/EmojiImg';
import { useAppContext } from '@/contexts/AppContext';
import useTableRequest, { getColumnSearchProps, getTableColumn } from '@/hooks/useTableRequest';
import { getKeywords } from '@/pages/system/keyword/const.var';
import { conversationDownload, getConversationPage } from '@/services/conversation';
import type { ConversationPageParam, ConversationPageVO } from '@/services/conversation/type';
import type { QueryKeywordCategoryItem } from '@/services/keyword_category/type';
import { DateUtils } from '@/utils/dateUtil';
import { Space, Spin, Table, TableColumnType, Typography } from '@antd';
import { useBoolean, useRequest, useSafeState } from 'ahooks';
import { flatten, get, isEmpty, padStart } from 'lodash';
import DrawerDetail, { getContentText } from '../DrawerDetail';
import { usePermission } from '@/hooks/usePermission';
import { AUTH } from '@/constants/permission-code';
import { dict } from '@/hooks/useChangeLocale';

const { Text, Link } = Typography;

type ListPros = {
  params: RequestSearchParams;
  category: QueryKeywordCategoryItem[];
  queryAll: boolean;
  queryUnclassified: boolean;
};

const List = ({ params, category, queryAll, queryUnclassified }: ListPros) => {
  const { user } = useAppContext();
  const [drawerVisible, { setTrue: openDrawer, setFalse: closeDrawer }] = useBoolean(false);
  const [drawerData, setDrawerData] = useSafeState<ConversationPageVO | undefined>();
  const [queryParams, setQueryParams] = useSafeState<RequestSearchParams>({});

  const { run: download, loading: downloading } = useRequest(
    () => conversationDownload(queryParams as ConversationPageParam),
    { manual: true },
  );

  const { tableProps } = useTableRequest(
    ({ pageIndex, pageSize, filters = {}, sorter }) => {
      const { sessionList, title, categoryList } = filters;
      const _queryAll = queryAll && isEmpty(categoryList);
      let categoryIds = category.map((v) => v.id).join(',');

      if (queryAll && !isEmpty(categoryList)) {
        categoryIds = categoryList.join(',');
      }

      const sortParams: RequestSortParams = {};

      if (sorter && sorter.field && sorter.order) {
        sortParams.sort = sorter.field;
        sortParams.direction = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
      }

      const query = {
        ...params,
        queryAll: _queryAll,
        queryUnclassified,
        searchWord: get(sessionList, '[0]'),
        title: get(title, '[0]'),
        bid: user?.bid,
        categoryIds,
        ...sortParams,
      };
      setQueryParams(query);
      return getConversationPage({
        ...query,
        pageIndex,
        pageSize,
      });
    },
    { refreshDeps: [params, category, user], debounceWait: 100 },
    false,
  );

  const { getBtnPermissionConfig } = usePermission();

  return (
    <>
      <Space style={{ display: 'flex', marginBlockEnd: 10, justifyContent: 'right' }}>
        <Spin spinning={downloading}>
          <Link onClick={download} {...getBtnPermissionConfig(AUTH.INTERACTIVE_CONVERSATION.DOWNLOAD)}>
            {dict('ACTION_DOWNLOAD_DATA')}
          </Link>
        </Spin>
      </Space>
      <Table
        {...tableProps}
        columns={
          [
            getTableColumn<ConversationPageVO>({
              title: dict('USER'),
              dataIndex: 'nickName',
              fixed: 'left',
              render: (_, record) => (
                <AccountAvator avaterUrl={record?.guestAvatarUrl} name={record?.guestName ?? ''} />
              ),
            }),
            getTableColumn<ConversationPageVO>({
              title: dict('SESSION_RECORDS'),
              dataIndex: 'sessionList',
              ...getColumnSearchProps({
                dataIndex: 'sessionList',
              }),
              onFilter: undefined,
              width: 200,
              render: (_, record) => {
                if (isEmpty(record?.sessionList)) {
                  return <span>-</span>;
                }
                const showSessionLength = 2;

                const sessionList = record.sessionList?.slice(0, showSessionLength) ?? [];
                const showTotal = (record.sessionList?.length ?? 0) > showSessionLength;

                return (
                  <Space
                    direction="vertical"
                    size={5}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDrawerData(record);
                      openDrawer();
                    }}
                  >
                    {sessionList.map((session) => {
                      const isFromMe = record.userId != session.sender;
                      const avatarName = (isFromMe ? record.originalEnterpriseName : record.guestName) ?? '';
                      const content = getContentText(session);
                      if (!content) return '';
                      return (
                        <div key={session.id}>
                          <div>
                            <Space>
                              <Text type="secondary">{avatarName}</Text>
                              <Text type="secondary">{DateUtils.formatDateTime(session.createTime)}</Text>
                            </Space>
                          </div>
                          <EmojiImgText
                            content={content}
                            searchWord={queryParams.searchWord}
                            keywords={getKeywords(record.keywordMap)}
                          />
                        </div>
                      );
                    })}
                    {showTotal ? <Link>{dict('TOTAL_COUNT', { total: record.sessionList?.length })}</Link> : null}
                  </Space>
                );
              },
            }),
            getTableColumn<ConversationPageVO>({
              title: dict('MESSAGE_COUNT'),
              dataIndex: 'count',
              sorter: true,
              render: (v, record) => {
                return (
                  <Text
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDrawerData(record);
                      openDrawer();
                    }}
                  >
                    {v}
                  </Text>
                );
              },
            }),

            queryAll
              ? getTableColumn<ConversationPageVO>({
                  title: dict('KEYWORD_CLASSIFICATION'),
                  dataIndex: 'categoryList',
                  ellipsis: true,
                  filters: flatten([category]).map(({ id, name }) => ({ text: name, value: id })),
                  filteredValue: params.categoryList,
                  render: (labels) => labels?.join(',') || '-',
                })
              : null,

            getTableColumn<ConversationPageVO>({
              title: dict('KEYWORD_HIT'),
              dataIndex: 'keywordList',
              ellipsis: true,
              render: (labels) => labels?.join(',') || '-',
            }),
            getTableColumn<ConversationPageVO>({
              title: dict('SESSION_TIME'),
              dataIndex: 'createTime',
              sorter: true,
              align: 'left',
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn<ConversationPageVO>({
              title: dict('SESSION_END_TIME'),
              dataIndex: 'closeTime',
              align: 'left',
              render: (v) => DateUtils.formatDateTime(v),
            }),
            getTableColumn<ConversationPageVO>({
              title: dict('SESSION_DURATION'),
              render: (v, record) => {
                if (!record.createTime || !record.closeTime) {
                  return '-';
                }
                return [
                  DateUtils.diffIn(record.createTime, record.closeTime, 'hours'),
                  DateUtils.diffIn(record.createTime, record.closeTime, 'minutes') % 60,
                  DateUtils.diffIn(record.createTime, record.closeTime, 'seconds') % 60,
                ]
                  .map((v) => padStart(v.toString(), 2, '0'))
                  .join(':');
              },
            }),
            getTableColumn<ConversationPageVO>({
              title: dict('SESSION_ACCOUNT'),
              render: (v, record) => {
                return <AccountAvator avaterUrl={record.avatar} name={record.originalEnterpriseName ?? ''} />;
              },
            }),

            getTableColumn<ConversationPageVO>({
              title: dict('ACTION_OPERATE'),
              align: 'center',
              fixed: 'right',
              width: 100,
              render: (v, record) => {
                return (
                  <Space>
                    <Link
                      {...getBtnPermissionConfig(AUTH.INTERACTIVE_CONVERSATION.VIEW)}
                      onClick={() => {
                        setDrawerData(record);
                        openDrawer();
                      }}
                    >
                      {dict('ACTION_VIEW')}
                    </Link>
                  </Space>
                );
              },
            }),
          ].filter((v) => v) as TableColumnType<ConversationPageVO>[]
        }
      />
      {drawerData && (
        <DrawerDetail
          record={drawerData}
          open={drawerVisible}
          onClose={closeDrawer}
          afterOpenChange={(open) => {
            if (!open) {
              setDrawerData(undefined);
            }
          }}
        />
      )}
    </>
  );
};
export default List;
