import ChartCore from '@/components/ChartCore';
import type { HomeUserAnalysisResult } from '@/services/home/type';
import { useCreation } from 'ahooks';
import { get, max } from 'lodash';
import { getPieChartOptions } from './chart.config';
import { Validator } from '@/utils/validator';
import { dict } from '@/hooks/useChangeLocale';

const getFieldValue = (dataObj, fields: string[]) => {
  return max(fields.map((field) => get(dataObj, field, 0)));
};
const Chart = ({
  data,
  showLabelRate,
  valueEqRate,
}: {
  data?: HomeUserAnalysisResult[];
  showLabelRate?: boolean; //显示占比
  valueEqRate?: boolean; //value值是否和占比值相等
}) => {
  const dataObj = useCreation(() => {
    return data
      ?.filter((v) => ['gender', 'sex_list'].includes(v.audienceCharacterType))
      .reduce((obj, cur) => {
        obj[cur.dimensions] = cur.metrics;
        return obj;
      }, {});
  }, [data]);

  return (
    <ChartCore
      empty={Validator.isNilEmpty(dataObj)}
      option={getPieChartOptions(
        [
          { value: getFieldValue(dataObj, ['male', '1', '男性', dict('MALE')]), name: dict('MALE') },
          { value: getFieldValue(dataObj, ['female', '2', '女性', dict('FEMALE')]), name: dict('FEMALE') },
          { value: getFieldValue(dataObj, ['未知', 'unknow']), name: dict('UNKNOWN') },
        ],
        showLabelRate,
        valueEqRate,
      )}
      notMerge
      style={{ height: 300 }}
    />
  );
};

export default Chart;
