import GoBackBar from '@/components/GoBackBar';
import Container, { Blank, FormCard } from '@/components/PageCard';
import { getAccount } from '@/services/account';
import { ProCard } from '@ant-design/pro-components';
import { Descriptions, Form, Typography } from '@antd';
import { useRequest } from 'ahooks';
import { useParams, useSearchParams } from 'umi';
import { ReactElement } from 'react';
import { MEDIA_KEY_ENUM, getMediaName } from '@/constants/const';
import { dict } from '@/hooks/useChangeLocale';

type AccountReportTopProps = {
  children: (accountId, detail) => ReactElement;
};
const { Text } = Typography;

export const AccountReportPageTop = (props: AccountReportTopProps) => {
  const [form] = Form.useForm();
  const [search] = useSearchParams();
  const accountId = `${search.get('accountId')}`;

  const { id } = useParams();

  const { data: detail, loading } = useRequest(
    async () => {
      const detail = await getAccount(id);
      form.setFieldsValue({
        ...detail,
      });
      return detail;
    },
    { refreshDeps: [id] },
  );

  return (
    <Container loading={loading} pageHeaderRender={() => <GoBackBar name={dict('VIEW_REPORT')} />}>
      <FormCard>
        <Descriptions title={detail?.name} column={3}>
          <Descriptions.Item label={dict('AUTHORIZED_ACCOUNT')}>
            <Text ellipsis style={{ maxWidth: 200 }} title={detail?.uniqueId}>
              {detail?.uniqueId || '-'}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label={dict('PLATFORM')}>
            {getMediaName(detail?.mediaId as MEDIA_KEY_ENUM)}
          </Descriptions.Item>
          <Descriptions.Item label={dict('AFFILIATION_ORG')}>{detail?.orgName}</Descriptions.Item>
        </Descriptions>
      </FormCard>
      <Blank />
      <ProCard>{detail ? props.children(accountId, detail) : null}</ProCard>
    </Container>
  );
};
